import React from 'react';
import { View } from 'react-native';

import { Link, PARKING_HREF, Text, useTheme } from '@almond/ui';

import { themedStyles } from './styles';

import type { AppointmentInfoOut } from '@almond/api-types';
import type { StyleProp, ViewStyle } from 'react-native';

export type PatientVisitItemProps = {
  item: AppointmentInfoOut;
  style?: StyleProp<ViewStyle>;
};

export const PatientVisitItem: React.FC<PatientVisitItemProps> = props => {
  const { item, style } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={[styles.item, style]}>
      <Text style={styles.itemTitle} fontStyle="medium" size="m">
        {item.appointmentTime}
      </Text>
      <View>
        <Text style={styles.text} size="m">
          {/* eslint-disable-next-line max-len */}
          {`${item.isTelehealth ? 'Video' : 'Office'} visit with ${item.providerName} | ${item.visitReasons.join(', ')}`}
        </Text>
        <Text style={styles.text} size="m">
          {item.rebookingUrl && (
            <Link size="m" url={item.rebookingUrl}>
              {'Reschedule or cancel'}
            </Link>
          )}
          {!item.isTelehealth && item.rebookingUrl && ' | '}
          {!item.isTelehealth && (
            <Link size="m" url={PARKING_HREF}>
              {'Parking info & directions'}
            </Link>
          )}
        </Text>
      </View>
    </View>
  );
};
