import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  item: {
    rowGap: sizes.SPACING_XS,
  },

  itemTitle: {
    color: theme.darkText,
  },

  text: {
    color: theme.tooltip,
  },
}));
