/* eslint-disable @typescript-eslint/naming-convention */
import { sizes, styleSheetFactory } from '@almond/ui';

export default styleSheetFactory(theme => ({
  background: {
    backgroundColor: theme.lightSecondaryBackground,
    flex: 1,
  },

  container: {
    gap: sizes.SPACING_M,
    marginVertical: sizes.SPACING_L,
    flexWrap: 'wrap',
    flexDirection: 'row',
    maxWidth: 1010,
  },

  containerDesktop: {
    width: '80%',
    alignSelf: 'center',
    justifyContent: 'space-between',
  },

  containerMobile: {
    marginHorizontal: sizes.SPACING_M,
  },

  containerColumn: {
    rowGap: sizes.SPACING_M,
  },

  containerColumn1: {
    'min-width': 'min(400px, 100%)',
    flex: 2,
  } as any,

  containerColumn2: {
    'min-width': 'min(320px, 100%)',
    flex: 1,
  } as any,

  title: {
    marginVertical: sizes.SPACING_XXL,
    color: theme.text,
    height: 100,
  },
}));
