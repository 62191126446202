/* src/ui/components/Accordion/Accordion.module.css */
.Accordion_expandClickableArea {
  position: initial;
  z-index: 1;
}
.Accordion_expandClickableArea:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}
.Accordion_caret {
  z-index: 0;
}
.Accordion_rightAction {
  z-index: 2;
}
.Accordion_collapsable {
  interpolate-size: allow-keywords;
  transition: all 0.3s ease allow-discrete;
  height: auto;
  overflow: hidden;
  display: block;
}
.Accordion_hidden {
  height: 0;
  display: none;
}

/* src/ui/components/ActivityIndicator/ActivityIndicator.module.css */
@keyframes ActivityIndicator_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ActivityIndicator_pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.ActivityIndicator_spinner {
  animation: ActivityIndicator_spin 1s linear infinite;
  border-left-color: #7b9bd1;
  border-right-color: #7b9bd1;
  border-top-color: #7b9bd1;
  border-bottom-color: transparent;
}
@media (prefers-reduced-motion: reduce) {
  .ActivityIndicator_spinner {
    animation: ActivityIndicator_pulse 1.5s ease-in-out infinite;
    border-bottom-color: #7b9bd1;
  }
}

/* src/ui/components/ToastNotification/ToastNotificationList.module.css */
.ToastNotificationList_container {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 24px;
  gap: 8px;
}
.ToastNotificationList_item {
  padding: 8px;
  border-radius: 8px;
  transition: transform 0.3s ease-out;
  transform: none;
}
@starting-style {
  .ToastNotificationList_item {
    transform: translateX(calc(100% + 24px));
  }
}
.ToastNotificationList_infoTheme {
  background-color: var(--color-info);
}
.ToastNotificationList_errorTheme {
  color: var(--color-background);
  background-color: var(--color-error);
}
.ToastNotificationList_text {
  color: inherit;
}
.ToastNotificationList_srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
/*# sourceMappingURL=index.css.map */