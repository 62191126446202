.container,
.containerLite {
  /* This is necessary because CSS Modules does not obfuscate these values in grid-template-columns, but it does
    in grid-column. This prevents any obfuscation */
  --content-start: content-start;
  --title-start: title-start;
  --title-end: title-end;
  --content-end: content-end;
}

.container {
  background: var(--color-background);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  --collapsed-height: 76px;
}

.containerLite .todoRow {
  /* prettier-ignore */
  grid-template-columns: [var(--content-start)] 20px [var(--title-start)] auto [var(--title-end)] 20px [var(--content-end)];
  --collapsed-height: 50px;
}

.todoRow {
  display: grid;
  column-gap: 8px;
  align-items: center;

  /* Copied into <ValidationMessage> */
  --horizontal-padding: 18px;

  /* prettier-ignore */
  grid-template-columns: var(--horizontal-padding) [var(--content-start)] 20px [var(--title-start)] auto [var(--title-end)] 20px [var(--content-end)] var(--horizontal-padding);
}

.alwaysVisibleContent {
  grid-template-rows: var(--collapsed-height);
}
.leftIcon {
  grid-column: var(--content-start) / var(--title-start);
  grid-row: 1 / 2;
}
.caret {
  transform: rotate(calc(var(--rotate, 0) * 90deg));
  transform-origin: top center;
  transition: transform 0.2s ease-in-out;
  width: 7px;
  height: 16px;
}
.caretCollapsed {
  --rotate: 1;
}
.caretExpanded {
  --rotate: -1;
}
.titleContainer {
  grid-column: var(--title-start) / var(--title-end);
  gap: 2px;
}
.dueDate {
  color: var(--color-secondary-text);
}
.expandButton {
  grid-column: var(--title-end) / var(--content-end);
  align-self: stretch;
  display: flex;
  place-content: center;
  /* Increase button hit area */
  margin-inline: calc(-1 * var(--horizontal-padding));
  padding-inline: var(--horizontal-padding);
}
.expandedSection {
  grid-template-rows: auto auto;
}
.description {
  grid-column: var(--title-start) / var(--title-end);
}
.description p,
.description ul,
.description ol {
  /* Prevent text from starting too far down */
  margin-top: 0;
}
.description ul {
  padding-left: 20px;
}
.description ol {
  padding-left: 30px;
}
.buttons {
  grid-column: 1 / 6;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  gap: 1px;
}
.hidden {
  display: none;
}

.description,
.title {
  word-break: break-word;
}
