import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  footer: {
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
  },

  link: {
    textAlign: 'center',
  },
}));
