import React from 'react';
import { View } from 'react-native';

import { useTheme } from '@almond/ui';
import { useToggle } from '@almond/utils';

import { useAlmondApiQuery } from '~/modules/api';
import { Error } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { PatientVisitItem } from '../PatientVisitItem';
import { SectionWithItems } from '../SectionWithItems';
import { ViewMoreButton } from '../ViewMoreButton';

import { themedStyles } from './styles';

const COLLAPSED_COUNT = 2;

export const UpcomingVisits: React.FC = () => {
  const currentUser = useCurrentUser();
  const { data, error, isLoading } = useAlmondApiQuery(
    currentUser?.patientUuid
      ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/appointments/future_appointments`
      : null
  );
  const [expanded, toggleExpanded] = useToggle(false);
  const [styles] = useTheme(themedStyles);

  if (error) return <Error error={error} />;
  if (data && !data?.appointments.length) return null;

  const content = (() => {
    const slicedAppointment = expanded ? data?.appointments : data?.appointments.slice(0, COLLAPSED_COUNT);

    return (
      <View style={styles.contentContainer}>
        {slicedAppointment?.map(appointment => <PatientVisitItem key={appointment.rebookingUrl} item={appointment} />)}
      </View>
    );
  })();

  return (
    <SectionWithItems
      mode="accent"
      title="Upcoming Visits"
      isLoading={isLoading}
      footer={
        data &&
        data?.appointments.length > COLLAPSED_COUNT && <ViewMoreButton isExpanded={expanded} onPress={toggleExpanded} />
      }
    >
      {content}
    </SectionWithItems>
  );
};
