import { getShadow, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    ...getShadow(),
    backgroundColor: theme.background,
    borderRadius: sizes.SPACING_M,
    overflow: 'hidden', // for border-radius of footer button hover effect
  },

  content: {
    overflow: 'hidden', // for collapsed accordion view
    paddingVertical: 20,
    paddingHorizontal: sizes.SPACING_L,
  },

  containerAccent: {
    backgroundColor: theme.accentBackground,
  },

  contentWithFooter: {
    paddingBottom: sizes.SPACING_L,
    marginBottom: 0,
  },

  title: {
    marginBottom: sizes.SPACING_S,
    color: theme.accent,
  },

  footer: {
    borderTopStyle: 'solid',
    borderTopColor: theme.divider,
    borderTopWidth: 1,
  },
}));
