import { CIRCULAR_STD_BOOK, sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  text: {
    color: theme.tooltip,
  },

  item: {
    rowGap: sizes.SPACING_XS,
  },

  itemTitle: {
    color: theme.darkText,
  },

  link: {
    fontSize: sizes.FONT_SIZE_BASE,
    fontFamily: CIRCULAR_STD_BOOK,
    textDecorationLine: 'underline',
    color: theme.primary,
  },

  contentContainer: {
    rowGap: 15,
    marginTop: 15,
  },
}));
