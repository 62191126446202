import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  button: {
    minWidth: 75,
    height: 30,
  },

  contentContainer: {
    paddingVertical: sizes.SPACING_S,
  },
}));
