import { styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(() => ({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 36,
  },
}));
