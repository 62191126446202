import React from 'react';

import { Button, ScrollableModal, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { HowItWorks } from './HowItWorks';
import { Referral } from './Referral';
import { TermsOfReferral } from './TermsOfReferral';

import { themedStyles } from './styles';

import type { ModalProps } from '~/modules/ui';

export type ReferralModalProps = Omit<ModalProps, 'title'> & {
  referralCode?: string;
};

export const ReferralModal = (props: ReferralModalProps) => {
  const [styles] = useTheme(themedStyles);
  const { isMobile, isDesktop } = useBrowserTypeMap();
  const { referralCode, ...restProps } = props;

  return (
    <ScrollableModal
      {...restProps}
      containerStyle={isDesktop && styles.modal}
      contentContainerStyle={[styles.contentContainer, isMobile && styles.contentContainerMobile]}
      showClose
      testID="ReferralModal"
    >
      <Text style={styles.title} fontStyle="bold" size="xxl" accessibilityLevel={2}>
        {'Refer Friends'}
      </Text>
      <Text style={styles.textCenter}>
        {'Get $100 towards next year’s membership renewal for every friend who signs up for Almond.'}
      </Text>
      <Referral referralCode={referralCode} />
      <HowItWorks />
      <TermsOfReferral />
      <Button style={styles.doneButton} onPress={restProps.onRequestClose} size="m" testID="Done">
        {'Done'}
      </Button>
    </ScrollableModal>
  );
};
