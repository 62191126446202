import React from 'react';
import { View } from 'react-native';

import { Link, Text, useTheme } from '@almond/ui';
import { Link as RouterLink } from 'expo-router';

import { themedStyles } from './styles';

import type { Href } from 'expo-router';

type ProgramItemInfo = {
  title: string;
  description: string;
  learnMoreHref: string;
  bookTitle: string;
} & ({ bookUrl: string } | { bookHref: Href });

export type ProgramItemProps = {
  item: ProgramItemInfo;
};

export const ProgramItem: React.FC<ProgramItemProps> = props => {
  const { item } = props;
  const [styles] = useTheme(themedStyles);

  return (
    <View style={styles.item}>
      <Text style={styles.itemTitle} fontStyle="medium" size="m">
        {item.title}
      </Text>
      <View>
        <Text style={styles.text} size="m">
          {item.description}
        </Text>
        <Text size="m">
          <Link url={item.learnMoreHref} size="m">
            {'Learn More'}
          </Link>
          {' | '}
          {'bookUrl' in item ? (
            <Link size="m" url={item.bookUrl}>
              {item.bookTitle}
            </Link>
          ) : (
            <RouterLink href={item.bookHref} style={styles.link}>
              {item.bookTitle}
            </RouterLink>
          )}
        </Text>
      </View>
    </View>
  );
};
