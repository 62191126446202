import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  background: {
    flex: 1,
  },
  scrollContainer: {
    scrollbarGutter: 'stable both-edges',
    backgroundColor: theme.lightSecondaryBackground,
  } as any,
  wrapper: {
    paddingVertical: 40,
    paddingHorizontal: sizes.SPACING_M,
    marginBottom: sizes.SPACING_XXL,
    maxWidth: 440 + 2 * sizes.SPACING_M,
    width: '100%',
    marginHorizontal: 'auto',
    alignItems: 'center',
  },
}));
